<template>
    <div>
        <d-loading :isLoading="isLoading"></d-loading>
    <div>
      <router-link to="/admin/stores/new">
      <v-btn large color="primary">
        <v-icon small="" left="">fas fa-plus</v-icon>Thêm cửa hàng
      </v-btn>
    </router-link>
    <!-- <router-link style="float:right" to="/party-a/report/signboards">
      <v-btn large color="primary">
        <v-icon small="" left="">fas fa-scroll</v-icon>Bản đồ biển quảng cáo
      </v-btn>
    </router-link> -->
    </div>
    
    <v-row >
            <v-col v-for="(item,index) in tableData" :key="index" cols="12" xs="12" sm="6" md="4" lg="3">
                <SingleStoreCard :store="item"></SingleStoreCard>
            </v-col>
        </v-row>
  </div>
</template>
<script>
import SingleStoreCard from './components/SingleStoreCard'
import {getAllStore} from '@/api/stores'
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    data() {
        return {
            tableData:[],
            isLoading:false
        }
    },
    methods:{
        fetchData(){
            this.isLoading = true;
            getAllStore().then(res=>{
                this.isLoading = false;
                this.tableData = res.data;
            })
        }
    },
    created(){
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Cửa hàng", route: "/admin/stores" },
        ]);
        this.fetchData();
    },
    components:{
        SingleStoreCard
    }
}
</script>
<template>
  <v-card class="mx-auto" max-width="344">
    <v-img src="/assets/store-icon.png" height="200px"></v-img>

    <v-card-title>
      <b>{{store.store_name}}</b>
    </v-card-title>

    <v-card-subtitle>{{store.store_address}}</v-card-subtitle>
    <v-card-subtitle style="margin-top:-20px"></v-card-subtitle>

    <v-card-actions style="margin-top:-20px">
      <a target="_blank" :href="'https://www.google.com/maps/search/?api=1&query='+store.store_lat+','+store.store_lng">
        <v-btn small icon color="purple">
          <v-icon small>fas fa-map-marked-alt</v-icon>
        </v-btn>
      </a>
      <router-link :to="'/admin/stores/'+store.id" style="float:right">
        <v-btn color="blue" text>Chi tiết</v-btn>
      </router-link>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    store: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>